import { cn } from "~/utils/shadecn"

export interface TypographyProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function Typography({ className, style, children }: TypographyProps) {
  return (
    <div
      className={cn("prose dark:prose-invert prose-a:text-primary", className)}
      style={{ maxWidth: "none", ...style }}>
      {children}
    </div>
  )
}
